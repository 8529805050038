import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/logo.png';
import './styles.scss';

function Nav() {
  return (
    <nav className="nav">
      <div className="nav__logo">
        <NavLink to="/">
          <img src={Logo} alt="Molu logo" />
        </NavLink>
      </div>
    </nav>
  );
}

export default Nav;
