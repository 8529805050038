import useDocumentTitle from '../../features/pageTitle/pageTitle';
import './styles.scss';
import Hahaa from '../../assets/hahaa.png';
import BlobAnimation from '../../features/blob-animation';


function LostPage() {
  useDocumentTitle('You lost?');
  
  return (
    <main>
      <div className="lost-page">
          <div className="lost-page__title">
            <img className="section-image" alt="Turtle Emote" src={Hahaa} />
            <h2>Foot</h2>
          </div>
          <p>You are in the wrong spot now.</p>
      </div>
      <BlobAnimation />
    </main>
  );
}

export default LostPage;
