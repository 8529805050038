import Lottie from 'react-lottie';
import animationData from '../../assets/lottie_blobs.json';
import animationDataMobile from '../../assets/lottie_moble.json';
import { useEffect, useState } from 'react';

function BlobAnimation() {
  const [mobileView, setMobileView] = useState(window.innerWidth > 600 ? false : true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 600) {
        setMobileView(false);
      } else if (window.innerWidth < 600) {
        setMobileView(true);
      }
    };
    
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: mobileView ? animationDataMobile : animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Lottie
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
        zIndex: 0,
      }}
      options={defaultOptions}
    />
  );
}

export default BlobAnimation;
