import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Nav from '../../features/nav';
import Socials from '../../features/socials';
import Home from '../home';
import LostPage from '../lost-page';
import Twitch from '../twitch';
import './styles.scss';
import ReactGA from 'react-ga';

function Root() {
  const TRACKING_ID = "UA-230072703-1"; // YOUR_OWN_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
  return (
    <Router>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/twitch" element={<Twitch />} />
        <Route path="*" element={<LostPage />} />
      </Routes>
      <Socials />
    </Router>
  );
}

export default Root;
