import useDocumentTitle from '../../features/pageTitle/pageTitle';
import Turtle from '../../assets/turtle.png';
import Molu from '../../assets/molu.png';
import './styles.scss';
import BlobAnimation from '../../features/blob-animation';

function Root() {
  useDocumentTitle('Home 💩');
  
  return (
    <main className="home-page">
      <div className="scroll-container">
        <section className="home-page__upsell">
          <div>
            <h2>Molu</h2>
            <p>Friendo Extraordinaire</p>
          </div>
        </section>
        <section>
          <div>
            <img className="section-image" alt="Turtle Emote" src={Turtle} />
            <p>Friend of Turtles</p>
          </div>
        </section>
        <section>
          <div>
            <img className="section-image" alt="Molu Emote" src={Molu} />
            <p>Enemy of Pasta</p>
          </div>
        </section>
      </div>
      <BlobAnimation />
    </main>
  );
}

export default Root;
