import React from 'react';
import useDocumentTitle from '../../features/pageTitle/pageTitle';
import './styles.scss';

function Root() {
  useDocumentTitle('Twitch');

  return (
    <main className="twitch-page">
      <div>
        <svg viewBox="0 0 960 300">
          <symbol id="s-text">
            <text textAnchor="middle" x="50%" y="80%">MOLU</text>
          </symbol>

          <g className = "g-ants">
            <use xlinkHref="#s-text" className="letter"></use>
            <use xlinkHref="#s-text" className="letter"></use>
            <use xlinkHref="#s-text" className="letter"></use>
            <use xlinkHref="#s-text" className="letter"></use>
            <use xlinkHref="#s-text" className="letter"></use>
          </g>
        </svg>
      </div>
      <iframe src="https://player.twitch.tv/?channel=molu&parent=www.molu.gg&parent=molu.gg" title="Molu's Twitch Channel" frameBorder="0" allowFullScreen scrolling="no" height="378" width="620" />
    </main>
  );
}

export default Root;
