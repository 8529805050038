import React from 'react';
import './styles.scss';

function Social() {
  return (
    <nav className="socials">
      <div className="socials__logo socials__twitch">
        <a href="https://www.twitch.tv/molu"  title="Twitch">
          <figure>
            <svg type="color-fill-current" width="26" height="26" version="1.1" viewBox="0 0 24 26" x="0px" y="0px">
              <g fillRule="evenodd">
                <path d="M19 6v6h-2V6h2zm-7 0h2v6h-2V6zM5 0L0 5v18h6v5l5-5h4l9-9V0H5zm17 13l-4 4h-4l-4 4v-4H6V2h16v11z"/>
                <path d="M18 17l4-4V2H6v15h4v4l4-4h4zM12 6h2v6h-2V6zm7 0h-2v6h2V6z" fill="#FFF"/>
              </g>
            </svg>
          </figure>
        </a>
      </div>
      <div className="socials__logo socials__youtube">
        <a href="https://www.youtube.com/channel/UCsOYXmCcpQHbdnNdTr-FDCA" title="Youtube">
          <figure>
            <svg type="color-fill-current" width="26" height="26" version="1.1" viewBox="0 0 20 20" x="0px" y="0px">
              <path fillRule="evenodd" d="M8.16 12.676V7.323L12.863 10 8.16 12.676zm10.464-7.037a2.26 2.26 0 00-1.592-1.602C15.628 3.659 10 3.659 10 3.659s-5.628 0-7.032.378a2.261 2.261 0 00-1.591 1.602C1 7.052 1 9.999 1 9.999s0 2.948.377 4.36c.207.78.817 1.394 1.59 1.603 1.405.38 7.033.38 7.033.38s5.628 0 7.032-.38a2.262 2.262 0 001.592-1.602C19 12.947 19 10 19 10s0-2.948-.376-4.361z" clipRule="evenodd"/>
            </svg>
          </figure>
        </a>
      </div>
      <div className="socials__logo socials__discord">
        <a target="blank" href="https://discord.gg/N6aPHcg" title="Joing the Molu Discord">
          <figure>
            <svg type="color-fill-current" width="26" height="26" version="1.1" viewBox="0 0 20 20" x="0px" y="0px">
              <path d="M15.545 4.91a13.195 13.195 0 00-3.257-1.01.05.05 0 00-.052.025c-.141.25-.297.577-.406.833a12.181 12.181 0 00-3.658 0 8.426 8.426 0 00-.412-.833.051.051 0 00-.052-.024 13.158 13.158 0 00-3.257 1.01.047.047 0 00-.021.018c-2.074 3.1-2.643 6.122-2.364 9.107.001.015.01.029.02.038a13.268 13.268 0 003.996 2.02.052.052 0 00.056-.02 9.48 9.48 0 00.818-1.329.05.05 0 00-.028-.07 8.746 8.746 0 01-1.248-.595.051.051 0 01-.005-.085c.084-.063.168-.128.248-.194a.05.05 0 01.051-.007c2.619 1.195 5.454 1.195 8.041 0a.05.05 0 01.053.006c.08.066.164.132.248.195a.051.051 0 01-.004.085c-.399.233-.813.43-1.249.594a.051.051 0 00-.027.071c.24.466.514.909.817 1.33a.05.05 0 00.056.018 13.225 13.225 0 004.001-2.02.051.051 0 00.021-.036c.334-3.452-.559-6.45-2.366-9.107a.04.04 0 00-.02-.02zM7.347 12.22c-.789 0-1.438-.724-1.438-1.613s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .889-.637 1.613-1.438 1.613zm5.316 0c-.788 0-1.438-.724-1.438-1.613s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .889-.63 1.613-1.438 1.613z"/>
            </svg>
          </figure>
        </a>
      </div>
    </nav>
  );
}

export default Social;
